"use client";

import clsx from "clsx";
import type { FormEvent } from "react";
import { useRef, useState, useCallback } from "react";
import type { NewsletterModel } from "@/app/(sites)/_api/newsletter/newsletter.types";
import { OutlineButton } from "@/components/Button/components/OutlineButton/OutlineButton.component";
import { RegularButton } from "@/components/Button/components/RegularButton/RegularButton.component";
import { SubmitButton } from "@/components/Button/components/SubmitButton/SubmitButton.component";
import { Email } from "@/components/Input/Email/Email.component";
import { NewsletterTermsCheckbox } from "@/components/Terms/NewsletterTermsCheckbox/NewsletterTermsCheckbox.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useFormValidation } from "@/hooks/use-form-validation.hook";
type NewsletterSignUpFormProps = {
  readonly model: NewsletterModel;
  readonly isPending: boolean;
  readonly onSubmit: (email: string) => void;
  readonly variant?: "primary" | "accent";
};
export function NewsletterSignUpForm({
  model: {
    description
  },
  isPending,
  onSubmit,
  variant = "primary"
}: NewsletterSignUpFormProps) {
  const [email, setEmail] = useState("");
  const formRef = useRef<HTMLFormElement>(null);
  const formRefSubmit = useRef<HTMLFormElement>(null);
  const {
    isFormValid,
    checkFormValidity
  } = useFormValidation(formRef);
  const {
    isFormValid: isFormSubmitValid,
    checkFormValidity: checkFormSubmitValidity
  } = useFormValidation(formRefSubmit);
  const [showTermsAndSubmit, setShowTermsAndSubmit] = useState(false);
  function setNewsletterBoxState(show: boolean) {
    setShowTermsAndSubmit(show);
  }
  const submit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stops the browser from refreshing the page upon submitting the form.

    if (!isFormValid) {
      return;
    }
    onSubmit(email);
  }, [isFormValid, onSubmit, email]);
  return <>
      <h1 className="mb-1 text-2xl font-theme-bold tracking-tight lg:text-3xl">
        <Translation model={{
        da: "Tilmeld dig vores nyhedsbrev",
        de: "Zum Newsletter anmelden",
        en: "Sign up for our newsletter",
        no: "Meld deg på vårt nyhetsbrev",
        sv: "Prenumerera på vårt nyhetsbrev"
      }} data-sentry-element="Translation" data-sentry-source-file="NewsletterSignUpForm.component.tsx" />
      </h1>

      {showTermsAndSubmit ? <form className="col-span-full row-start-2 flex flex-col gap-y-2 lg:col-span-3" data-testid="newsletter-sign-up-form" ref={formRefSubmit} onSubmit={submit}>
          <NewsletterTermsCheckbox isDisabled={isPending} isRequired onChange={checkFormSubmitValidity} />
          <div className="flex gap-1">
            <OutlineButton onClick={() => setNewsletterBoxState(false)}>
              <Translation da="Tilbage" de="Zurück" en="Back" no="Tilbake" sv="Tillbaka" />
            </OutlineButton>
            <SubmitButton className="w-full" disabled={!isFormSubmitValid} isPending={isPending} variant={variant}>
              <Translation model={{
            da: "Tilmeld",
            de: "Anmelden",
            en: "Subscribe",
            no: "Registrer",
            sv: "Prenumerera"
          }} />
            </SubmitButton>
          </div>
        </form> : <>
          <p className="leading-snug">{description}</p>
          <form className={clsx("col-span-full row-start-2 mt-8 flex flex-col gap-y-2 lg:col-span-3")} data-testid="newsletter-sign-up-form" ref={formRef} onSubmit={submit}>
            <Email disabled={isPending} name="email" required onChange={event => {
          setEmail((event.target as HTMLInputElement).value.trim().toLowerCase());
          checkFormValidity();
        }} />
            <RegularButton disabled={!isFormValid} onClick={() => setNewsletterBoxState(true)}>
              <Translation da="Fortsæt" de="Weiter" en="Continue" no="Fortsett" sv="Fortsätt" />
            </RegularButton>
          </form>
        </>}
    </>;
}